import makeStore from "./makeStore";

const gameInfoReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const [GameInfoProvider, useGameInfoStore, useGameInfoDispatch] = makeStore(
  gameInfoReducer,
  {}
);

export { GameInfoProvider, useGameInfoStore, useGameInfoDispatch };
