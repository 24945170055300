/* eslint-disable max-len */
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./GameTutorials.css";
import "./Modal.css";

export function GameTutorial({ gameID }) {
  switch (gameID) {
    case "forgetful-frog":
      return <ForgetfulFrogTutorial />;
    case "rainbow-run":
      return <RainbowRunTutorial />;
    default:
      return <></>;
  }
}

function ForgetfulFrogTutorial() {
  const responsive = {
    all: {
      breakpoint: { max: 10000, min: 0 }, // viewport max width
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div
      id="gameplay"
      className="width-100"
      style={{ backgroundColor: "#5BC7CC" }}
    >
      <Carousel showDots={true} responsive={responsive}>
        <img
          alt="Task: help the frog get its feast"
          src="games/forgetful-frog/tutorial/task.gif"
        />
        <img
          alt="Lilypads disappear"
          src="games/forgetful-frog/tutorial/disappear.gif"
        />
        <img
          alt="Hop with arrow keys"
          src="games/forgetful-frog/tutorial/hop_with_arrows.gif"
        />
        <img
          alt="If you fall, you fail"
          src="games/forgetful-frog/tutorial/fail.gif"
        />
      </Carousel>
    </div>
  );
}

export function RainbowRunTutorial() {
  const responsive = {
    all: {
      breakpoint: { max: 10000, min: 0 }, // viewport max width
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div
      id="gameplay"
      className="width-100"
      style={{ backgroundColor: "#D9F5FE" }}
    >
      <Carousel showDots={true} responsive={responsive}>
        <img
          alt="Escape from collapsing tiles"
          src="games/rainbow-run/tutorial/task+collapse.gif"
        />
        <img
          alt="Hop with arrow keys ASAP"
          src="games/rainbow-run/tutorial/hop_with_arrows.gif"
        />
        <img
          alt="Discover the patterns"
          src="games/rainbow-run/tutorial/pattern.gif"
        />
        <img
          alt="Fails when collapse catches you"
          src="games/rainbow-run/tutorial/fail.gif"
        />
      </Carousel>
    </div>
  );
}

function OtherTutorial() {
  return <></>;
}

export default GameTutorial;
