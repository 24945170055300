import React from "react";

function CloseButton({ onClose }) {
  return (
    <button id="close-button" className="close" onClick={onClose}>
      &times;
    </button>
  );
}

export default CloseButton;
