import makeStore from "./makeStore";

const playersReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...action.payload,
      };
    case "ADD_PLAYER":
      return {
        ...action.payload,
        ...state,
      };

    default:
      return state;
  }
};

const [PlayersProvider, usePlayersStore, usePlayersDispatch] = makeStore(
  playersReducer,
  {}
);

export { PlayersProvider, usePlayersStore, usePlayersDispatch };
