import { db } from "../services/firebase.js";
import { collection, addDoc } from "firebase/firestore";

async function emailSignUp(email) {
  return await addDoc(collection(db, "emails"), {
    email: email,
  });
}

export default emailSignUp;
