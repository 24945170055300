import React, { useState, useRef, useContext } from "react";

import { usePageTitleOnMount, useToggle } from "../../hooks/customHooks";
import CreateRoom from "../../components/CreateRoom";
import EmailSignUp from "../../components/EmailSignUp";
import RoomCodeEntry from "../../components/RoomCodeEntry";
import NameEntryModal from "../../components/NameEntryModal";
import "./Home.css";

import { getAuth } from "firebase/auth";
import checkIfRoomIsOpen from "../../helpers/check-if-room-is-open";
import createRoom from "../../helpers/create-room";
import joinRoomByCode from "../../helpers/join-room-by-code";
import { PartyContext } from "../../contexts/PartyContext";
import { usePlayersDispatch } from "../../store/playersStore";

function Home({ onPageSwitch, pageEntered, isMobile }) {
  usePageTitleOnMount("The Brain Game Lab");
  const [NEModalVisible, toggleNEModalVisible] = useToggle();
  const [SIModalVisible, toggleSIModalVisible] = useToggle();
  const [joinRoomMessage, setJoinRoomMessage] = useState("");
  const nextAction = useRef("CREATE");
  const roomCode = useRef(0);
  const { partyState, setPartyState } = useContext(PartyContext);
  const [roomID, setRoomID] = useState();
  const [nameEntryErrMsg, setNameErr] = useState("");

  const dispatchPlayers = usePlayersDispatch();

  const auth = getAuth();

  function handleRoomCodeSubmit(code) {
    checkIfRoomIsOpen(code).then((roomOpen) => {
      console.log(`roomOpen: ${roomOpen}`);

      if (roomOpen === "GOOD") {
        nextAction.current = "JOIN";
        roomCode.current = code;
        toggleSIModalVisible();
        toggleNEModalVisible();
      } else if (roomOpen === "STARTED") {
        setJoinRoomMessage(`The party in room 
          ${code.join("")} has already started.`);
      } else if (roomOpen === "FULL") {
        setJoinRoomMessage(`The room 
          ${code.join("")} has reached its maximum number of players.`);
      } else if (roomOpen === "NULL") {
        setJoinRoomMessage(`There is no room ${code.join("")}.`);
      } else if (roomOpen === null) {
        setJoinRoomMessage("Error joining room.");
      }
    });
  }

  function onColdStart(uid, username, roomcode) {
    console.log("switching to LOADING page.");
    console.log("room: ", roomcode);
    console.log("uid: ", uid);
    console.log("username: ", username);
    onPageSwitch({
      uid: uid,
      username: username,
      room: roomcode,
      state: "LOADING",
      gameID: null,
    });
  }

  function handleNameEntryModalSubmit(name) {
    if (nextAction.current === "CREATE") {
      createRoom(4, name).then((msg) => {
        if (msg.success) {
          console.log("successfully created room");
          toggleNEModalVisible();
          setNameErr("");
          // update partyState to conditionally hide navbar
          setPartyState({
            hasStarted: true,
          });

          setRoomID(msg.code);
          console.log("check roomID: ", roomID);
          // onPageSwitch({
          //   uid: auth.currentUser.uid,
          //   username: name,
          //   room: msg.room,
          //   state: 'LOADING',
          //   gameID: null
          // })
          onPageSwitch({
            state: "PARTY_LOBBY",
            room: msg.code,
            gameID: null,
            back: false,
          });
          const player = msg.player;
          dispatchPlayers({ type: "ADD_PLAYER", payload: player });
        } else {
          switch (msg.error) {
            case "cold_start":
              toggleNEModalVisible();
              setNameErr("");
              setPartyState({
                hasStarted: true,
              });
              onColdStart(auth.currentUser.uid, name, msg.room);
              break;
            case "empty_name":
              setNameErr("ERROR: Please enter a non-empty name.");
              break;
            //  a host can't have duplicate name because they are the first one in the room
            default:
              setNameErr("ERROR: Error joining the room.");
              break;
          }
        }
      });
    }
    if (nextAction.current === "JOIN") {
      joinRoomByCode(auth.currentUser.uid, name, roomCode.current).then(
        (msg) => {
          if (msg.success) {
            console.log("successfully joined room");
            toggleNEModalVisible();
            setNameErr("");
            // update partyState to conditionally hide navbar
            setPartyState({
              hasStarted: true,
            });
            onPageSwitch({ state: "PARTY_LOBBY", room: msg.room, back: false });
            const player = msg.player;
            dispatchPlayers({ type: "ADD_PLAYER", payload: player });
          } else {
            switch (msg.error) {
              case "cold_start":
                toggleNEModalVisible();
                setNameErr("");
                onColdStart(auth.currentUser.uid, name, msg.room);
                setPartyState({
                  hasStarted: true,
                });
                break;
              case "empty_name":
                setNameErr("ERROR: Please enter a non-empty name.");
                break;
              case "name_used":
                setNameErr(
                  "ERROR: This name is used by another player in the party."
                );
                break;
              default:
                setNameErr("ERROR: Error joining the room.");
                break;
            }
          }
        }
      );
    }
  }

  function handleNameEntryModalClose() {
    toggleNEModalVisible();
    setNameErr("");
  }

  function handleCreateRoomSubmit() {
    nextAction.current = "CREATE";
    toggleSIModalVisible();
    toggleNEModalVisible();
  }

  function handleSigninOptionsModalClose() {
    toggleSIModalVisible();
  }

  // for handling user click-on close without signing in
  function handleSigninOptionsModalCloseNoAuth() {
    toggleSIModalVisible();
    NEModalVisible && toggleNEModalVisible(); // not open the name modal
  }

  return (
    <div className="screen-wrap" id="home-page">
      <div className="page">
        <img src="img/logo.png" id="logo" alt="The Brain Game Lab" />
        <div className="card" id="partyMode">
          <img src="img/party.png" id="partyIcon" alt="Party Mode" />
          <p className="h2">PARTY MODE</p>
          <p className="bodyText">
            Compete against your friends in a series of games
          </p>

          <RoomCodeEntry
            onRoomCodeSubmit={handleRoomCodeSubmit}
            message={joinRoomMessage}
          />
          <CreateRoom onSubmit={handleCreateRoomSubmit} />
        </div>

        <EmailSignUp />
        <NameEntryModal
          visible={NEModalVisible}
          onClose={handleNameEntryModalClose}
          onSubmit={handleNameEntryModalSubmit}
          errormsg={nameEntryErrMsg}
        />
      </div>
    </div>
  );
}

export default Home;
