import React, { useEffect } from "react";
import { realtime_db } from "../../services/firebase.js";
import "./loading.css";
import loading_gif from "./bgl_loading_animation.gif";
import joinRoomByCode from "../../helpers/join-room-by-code";
import { usePlayersDispatch } from "../../store/playersStore";
import { ref, get, set, update, child, onValue, off } from "firebase/database";

// NOTE: this LoadingScreen component is used for loading from Home to PartyLobby only

function LoadingScreen({
  uid,
  username,
  roomID,
  isMobile,
  onPageSwitch,
  pageEntered,
}) {
  const dispatchPlayers = usePlayersDispatch();
  const room_state_ref = ref(realtime_db, `rooms/${roomID}/room_state/`);

  // just for debugging
  useEffect(() => {
    console.log("inside loading page...");
    console.log("roomcode: ", roomID);
    console.log("uid: ", uid);
    console.log("username: ", username);
  });

  // subscribe to room_state changes
  onValue(room_state_ref, (snapshot) => {
    const data = snapshot.val();
    console.log("seeing change in room state, room_state: ", data);
    if (data && data.stage === "waiting") {
      console.log("trying to join room...");
      joinRoomByCode(uid, username, roomID).then((msg) => {
        if (msg.success) {
          console.log("joined room");
          off(room_state_ref);
          console.log("removed db listener");
          onPageSwitch({ state: "PARTY_LOBBY", room: msg.room, gameID: null });
          const player = msg.player;
          dispatchPlayers({ type: "ADD_PLAYER", payload: player });
        } else {
          switch (msg.error) {
            case "cold_start":
              break;
            case "empty_name":
              console.log("ERROR: user input empty name, not catched");
              break;
            case "name_used":
              console.log(
                "ERROR: This name is used by another player in the party."
              );
              break;
            default:
              console.log("ERROR: Error joining the room.");
              break;
          }
        }
      });
    }
  });

  return (
    <div className="screen-wrap">
      <div className="page" id="loading_screen">
        <img id="loading_gif" src={loading_gif} alt="loading animation" />
        <p className="bodyText" id="loadingText">
          {" "}
          CREATING ROOM...
          <br />
          Hold tight! This may take a moment.{" "}
        </p>
      </div>
    </div>
  );
}

export default LoadingScreen;
