import React from "react";

function BackButton({ onClick }) {
  return (
    <img
      onClick={onClick}
      id="back-icon"
      src="/img/icons/arrow.png"
      alt="Go Back"
    />
  );
}

export default BackButton;
