import { useEffect, useState, useRef, useCallback } from "react";

import { serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db, realtime_db } from "../../services/firebase.js";
import IFrame from "../../components/Iframe";
import {
  usePageTitleOnMount,
  useRealtimeDatabaseEntry,
  useRoomDataEffect,
} from "../../hooks/customHooks.js";
import { useGameInfoStore } from "../../store/gameInfoStore.js";
import { useRoomInfoStore } from "../../store/roomInfoStore.js";
import { useRoomStateDispatch } from "../../store/roomStateStore";

import RemovalNotifyModal from "../../components/RemovalNotifyModal";

import { ref, onValue, get, child, update, set } from "firebase/database";
import { collection, addDoc, updateDoc } from "firebase/firestore";

function Game({ gameID, roomID, onPageSwitch, pageEntered, isMobile }) {
  usePageTitleOnMount("Game");

  const [RModalVisible, toggleRModal] = useState(false);
  const [contentWindow, setContentWindow] = useState(null);
  const activeSession = useRef(null);

  const { gameLevels } = useGameInfoStore();
  const curGame = useRealtimeDatabaseEntry(
    `/rooms/${roomID}/room_state/curGame`
  );
  const { partyId } = useRoomInfoStore();
  const dispatchRoomState = useRoomStateDispatch();

  const auth = getAuth();

  // load game in iframe
  useEffect(() => {
    if (contentWindow && pageEntered) {
      contentWindow.onload = () => {
        console.log("content-window: ", contentWindow);
        createGameSession();
        beginGame();
        window.addEventListener("message", handleMessageListener);
      };
    }
  });

  useRoomDataEffect(
    useCallback(
      (data) => {
        // remove player if not in the party
        if (!data.players[auth.currentUser.uid] && !RModalVisible) {
          console.log("player removed");
          toggleRModal(true);
          return;
        }
      },
      [RModalVisible, toggleRModal]
    ),
    roomID
  );

  function handleRemovalModalSubmit() {
    dispatchRoomState({ type: "LEAVE" });
    onPageSwitch({ state: "HOME", back: true });
  }

  function handleMessageListener(event) {
    console.log("calling message listener with msg: " + event.data.msg);
    if (event.data.msg === "writeData") {
      console.log("writing game data");
      writeGameData(event.data.level_data, event.data.performance_data);
    }
    if (event.data.msg === "endGame") {
      console.log("ending game");
      endGame(event.data.final_score);
    }
  }

  async function writeGameData(level_parameters, performance_data) {
    if (activeSession.current == null) {
      return;
    }
    try {
      await addDoc(collection(activeSession.current, "levels"), {
        level_parameters: JSON.parse(JSON.stringify(level_parameters)),
        performance: JSON.parse(JSON.stringify(performance_data)),
      });
      console.log("written data");
      return true;
    } catch (error) {
      console.log("Oops! Something went wrong with writing game data", error);
    }
  }

  function endGame(overall_score) {
    console.log("check end game");
    updateRoundScore(overall_score);
    let finishTime = serverTimestamp();
    endGameSession(finishTime);
    handlePageSwitch();
  }

  async function updateRoundScore(score) {
    try {
      // let room = await roomDoc.get();
      // const roomData = room.data();
      let roomRef = ref(realtime_db, `rooms/${roomID}`);
      let room = await get(child(roomRef, "party_id"));
      if (room && room.exists) {
        const uid = auth.currentUser.uid;

        await set(child(roomRef, `game_stats/${uid}/round_scores/${curGame}`), score);

        try {
          await set(child(roomRef, `playerStatus/${uid}/finishedGame`),true);
        } catch (error) {
          console.log("Error with updating player finishedGame status ", error);
        }
      }
    } catch (error) {
      console.log("Oops! Something went wrong with finishing the game.", error);
    }
  }

  async function endGameSession(finishTime) {
    try {
      if (activeSession.current != null) {
        await updateDoc(activeSession.current, {
          "timestamp.end": finishTime,
        });
        console.log("ended game session");
        return true;
      } else return false;
    } catch (error) {
      console.log(
        "Oops! Something went wrong with ending game session.",
        error
      );
    }
  }

  function handlePageSwitch() {
    console.log("handling page switch");
    window.removeEventListener("message", handleMessageListener);
    onPageSwitch({
      state: "POST_GAME",
      gameID: gameID,
      room: roomID,
      back: false,
    });
  }

  async function createGameSession() {
    var sessions = collection(db, `experiment_data/${gameID}/sessions`);
      
    try {
      const session = await addDoc( sessions, {
        user_id: auth.currentUser.uid,
        party_id: partyId,
        timestamp: {
          start: serverTimestamp(),
          end: null,
        },
      });
      // setActiveSession(session);
      activeSession.current = session;
      console.log(
        "success. created the game session. session: ",
        activeSession.current
      );
      return true;
    } catch (error) {
      console.error("error creating game session: ", error);
    }

  }

  async function beginGame() {
    const curGameLevels = gameLevels[gameID].levels;
    return contentWindow.gameAccessor.initialize(curGameLevels, isMobile);
  }

  return (
    <div className="screen-wrap" id="game-wrapper">
      <IFrame
        title="Game Window"
        contentWindowHandler={setContentWindow}
        src={`games/${gameID}`}
      />

      <RemovalNotifyModal
        visible={RModalVisible}
        onSubmit={handleRemovalModalSubmit}
      />
    </div>
  );
}

export default Game;
