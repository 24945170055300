import React, { useState } from "react";
import "./RoomCodeEntry.css";

function RoomCodeEntry({ onRoomCodeSubmit, message, reset }) {
  const [code, setCode] = useState(Array(4).fill(""));
  const [position, setPosition] = useState(0);
  // const isInitialMount = useRef(true);

  function updateCode(evt) {
    let newCode = code.slice();
    if (evt.key === "Backspace") {
      newCode[position] = "";
      setCode(newCode);
      if (position > 0) {
        let elementID = `code${position - 1}`;
        console.log(elementID);
        document.getElementById(elementID).focus();
        setPosition(position - 1);
      }
    } else {
      let number = Number(evt.key);
      if (isNaN(number)) {
        return;
      }
      if (position < 4) {
        newCode[position] = number;
        setCode(newCode);
      }
      if (position < 3) {
        let elementID = `code${position + 1}`;
        console.log(elementID);
        document.getElementById(elementID).focus();
        setPosition(position + 1);
      }
    }
    if (newCode.every((x) => x !== "")) {
      onRoomCodeSubmit(newCode);
      setCode(Array(4).fill(""));
      setPosition(0);
      let firstCode = document.getElementById("code0");
      if (firstCode) firstCode.focus();
    }
  }

  // useEffect(() => {
  //   if (isInitialMount.current === false) {
  //     document.querySelector(`#code${position}`).focus();
  //   } else {
  //     isInitialMount.current = false;
  //   }
  // }, [position])

  return (
    <div id="joinRoom">
      <p id="codeInstructions" className="instructionText">
        Enter a 4-digit code to join an existing party:
      </p>
      <form id="roomCode" onSubmit={() => {}}>
        {[0, 1, 2, 3].map((i) => (
          <input
            className="h2"
            type="number"
            value={code[i]}
            id={`code${i}`}
            key={`code${i}`}
            maxLength="1"
            onKeyDown={updateCode}
            onChange={(e) => console.log("on change")}
            // readOnly={code[i] === '' && i !== position}
          />
        ))}
      </form>
      <span id="codeMsg" className="instructionText">
        {message}
      </span>
    </div>
  );
}

export default RoomCodeEntry;
