import { realtime_db } from "../services/firebase.js";

async function checkIfRoomIsOpen(code) {
  let maxNumPlayers = 8;

  if (Array.isArray(code)) {
    code = code.join("");
  }
  let roomRef = realtime_db.ref("rooms/" + code);
  try {
    const room = await roomRef.get();
    console.log(room);
    if (room && room.exists) {
      const roomData = room.val();
      let numPlayers = Object.keys(roomData.players).length;
      if (roomData.room_state.started) {
        return "STARTED";
      } else if (numPlayers >= maxNumPlayers) {
        return "FULL";
      } else if (numPlayers > 0) {
        return "GOOD";
      }
    } else {
      return "NULL";
    }
  } catch (error) {
    console.log("Oops, Fail to join room ", error);
    return null;
  }
}

export default checkIfRoomIsOpen;
