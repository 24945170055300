// import { db } from '../services/firebase.js';

function selectRandomAvatar(usedAvatars) {
  const icons = [
    "bear",
    "bird",
    "bee",
    "cat",
    "cow",
    "dog",
    "elephant",
    "fox",
    "giraffe",
    "hippo",
    "koala",
    "lion",
    "monkey",
    "octopus",
    "panda",
    "rabbit",
    "raccoon",
    "tiger",
  ];

  let selectedAvatar = icons[Math.floor(Math.random() * icons.length)];
  while (usedAvatars.includes(selectedAvatar)) {
    selectedAvatar = icons[Math.floor(Math.random() * icons.length)];
  }
  return selectedAvatar;
}

export default selectRandomAvatar;
