import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UserContext } from "../contexts/UserContext";
import {
  useRealtimeDatabaseEntry,
  useRoomDataEffect,
} from "../hooks/customHooks";
import { realtime_db } from "../services/firebase";
import { useGameInfoStore } from "../store/gameInfoStore";
import { useGameStatsDispatch } from "../store/gameStatsStore";
import {
  useRoomStateDispatch,
  useRoomStateStore,
} from "../store/roomStateStore";

import { ref, set, get, update } from "firebase/database";

import "./ProceedButton.css";

function ProceedButton({ onPageSwitch, roomID }) {
  const roomState = useRealtimeDatabaseEntry(
    `/rooms/${roomID}/room_state/stage`
  );
  const { curGame } = useRoomStateStore();
  const { gameIDs } = useGameInfoStore();
  const { user, setUser } = useContext(UserContext);
  const dispatchRoomState = useRoomStateDispatch();
  const dispatchGameStats = useGameStatsDispatch();

  const [summary, setSummary] = useState(false);
  const [proceedText, setProceedText] = useState("Proceed to Next Game");

  const gameIndex = useRef();

  useEffect(() => {
    // save curGame to useRef() because curGame in GameStatsStore gets incremented
    // in pageSwitchLogic()
    // and we don't want to trigger the useEffect that will set summary to true and
    // move to Final Result
    // SHOULD ONLY RUN ONCE WHEN PAGE LOADS
    gameIndex.current = curGame;
  }, []);

  useRoomDataEffect(
    useCallback(
      (data) => {
        console.log("check update gamestats proceedbutton", data);
        dispatchGameStats({ type: "UPDATE", payload: data });
      },
      [dispatchGameStats]
    ),
    roomID,
    "game_stats"
  );

  useEffect(() => {
    if (gameIndex.current === gameIDs.length - 1 && roomState === "ranking") {
      setSummary(true);
      setProceedText("Final Result");
    }
  }, [gameIDs, roomState]);

  useRoomDataEffect(
    useCallback(
      (data) => {
        // switch to next game's lobby if everyone is proceeded
        if (data.room_state.stage === "lobby" && data.players[user.uid]) {
          onPageSwitch({
            state: "GAME_LOBBY",
            room: roomID,
            gameID: data.gameInfo.gameIDs[data.curGame + 1],
            back: false,
          });
        }
      },
      [onPageSwitch, roomID, user]
    ),
    roomID
  );

  async function pageSwitchLogic() {
    if (!summary) {
      await handleProceed();
      dispatchRoomState({ type: "NEXT_GAME" });
      setProceedText("Waiting to Proceed...");
    } else {
      console.log("switching to summary page");
      let res = await storePartyData();
      console.log("storePartyData result: ", res);
      if (res) {
        await handleProceed();
        onPageSwitch({
          state: "SUMMARY",
          room: roomID,
          back: false,
        });
      }
    }
  }

  async function storePartyData() {
    const roomRef = ref(realtime_db, `rooms/${roomID}`);
    try {
      const room = await get(roomRef);
      if (room && room.exists) {
        const roomData = room.val();
        console.log("check room data: ", room.val());
        // only store lastPartyData if this is the first user to party again
        if (!roomData.lastPartyData) {
          try {
            await update(roomRef, {
              lastPartyData: roomData,
            });
            console.log("stored to lastPartyData");
          } catch (e) {
            console.log("error storing last party data: ", e);
            return false;
          }

          // await roomRef.child('room_state').update({
          //     curGame: 0,
          //     started: false,
          //     stage: 'waiting',
          //     reParty: false
          // })
          // console.log("reset room for next party");
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Oops! Something went wrong with storing lastPartyData.");
    }
  }

  async function handleProceed() {
    try {
      let userStatusRef = ref(
        realtime_db,
        `rooms/${roomID}/playerStatus/${user.uid}`
      );
      let userStatus = await get(userStatusRef);
      if (userStatus && userStatus.exists) {
        try {
          await update(userStatusRef, {
            proceeded: true,
            ready: false,
            finishedGame: false,
          });
          console.log("UPDATED USER STATUS TO PROCEEDED");
        } catch (error) {
          console.log("error with updating user status", error);
        }
        console.log("updated the player to proceeded");
      }
    } catch (error) {
      console.log("Error getting roomRef:", error);
    }
  }

  return (
    <button id="proceed-button" onClick={pageSwitchLogic}>
      <span id="proceed-text" className="instructionText">
        {proceedText}
      </span>
      <img
        id="leaderboard-icon"
        className="icon"
        src="img/icons/correct.png"
        alt="ready"
      />
    </button>
  );
}

export default ProceedButton;
