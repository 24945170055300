import { getAuth } from "firebase/auth";
import { realtime_db } from "../services/firebase.js";
import { ref, get, push, child, set } from "firebase/database";
import joinRoomByCode from "./join-room-by-code.js";

async function createRoom(codeLength, host_display_name) {
  const auth = getAuth();

  const USER = auth.currentUser;

  if (USER) {
    // get list of all rooms that are currently active
    let activeRooms = ref(realtime_db, "active_rooms");

    let activeRoomsData = await get(activeRooms);

    // build an array of all the active room IDs
    let roomIDlist = activeRoomsData.val();

    let rooms = [];

    if (roomIDlist !== null) {
      for (const [, value] of Object.entries(roomIDlist)) {
        rooms.push(value.roomID);
      }
    }

    // generate a new random ID until we get one not on the list
    // TODO: handle edge case of no new room IDs??
    let newRoomID = randomDigitID(codeLength);
    while (rooms.includes(newRoomID)) {
      newRoomID = randomDigitID(codeLength);
    }

    // add the new room ID to the list of active rooms.
    var newRoomRef = await push(activeRooms);
    set(newRoomRef, { roomID: newRoomID });

    // initialize the new room
    await initRoom(newRoomID);

    const result = await joinRoomByCode(USER.uid, host_display_name, newRoomID);
    if (result.success) {
      const player = result.player;
      return { success: true, code: newRoomID, player: player };
    } else {
      return result;
    }
  } else {
    console.log("Whoa, this user is not signed in!");
    return { success: false, reason: "NOT-SIGNED-IN" };
  }
}

function randomDigitID(codeLength) {
  // 4 digits, 0-9, = 10^4 = 10,000
  const PERMUTATIONS = Math.pow(10, codeLength);

  // Set up variables
  let code;
  let padding = "";

  // Generate the code
  code = Math.floor(Math.random() * PERMUTATIONS);

  // Pad with 0's if necessary
  for (let i = 0; i < codeLength; i++) {
    padding += "0";
  }
  code = (padding + code).slice(-codeLength);

  return code;
}

async function initRoom(roomID) {
  let roomRef = ref(realtime_db, `/rooms/${roomID}`);

  // generate a unique ID for this party
  let party_id = Math.floor(Math.random() * 1000000000);
  party_id = party_id.toString().padStart(9, "0");

  await set(roomRef, {
    roomCode: roomID,
    party_id: party_id,
    gameInfo: { init: false },
  });
}

export default createRoom;
