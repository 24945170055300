import React, { useState, useEffect, useRef, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRoomDataEffect } from "../hooks/customHooks.js";
// import { realtime_db, db } from '../services/firebase';
import ProceedButton from "./ProceedButton";
import "./Ranking.css";
import PlayerAvatar from "./PlayerAvatar";
import sortPlayersResult from "../helpers/rank-players.js";
import { useRoomInfoStore } from "../store/roomInfoStore.js";

function RoundRankingNode({
  user,
  pair,
  lastRoundNum,
  hostID,
  roomID,
  online,
  players,
  playerStatus,
  curPlayerUID,
}) {
  const [key, value] = pair;
  const curPlayer = players[`${curPlayerUID}`];
  // const curPlayerStatus = playerStatus[`${curPlayerUID}`];

  return online ? (
    <>
      {key === user.uid ? (
        <div className="player-bar self">
          <PlayerAvatar
            user={user}
            state="POST_GAME"
            avatar={curPlayer.avatar}
            name={curPlayer.name}
            id={key}
            hostUID={hostID}
            room={roomID}
            isReady={false}
            isReturned={
              playerStatus[`${curPlayerUID}`]
                ? playerStatus[`${curPlayerUID}`].finishedGame
                : false
            }
            tooltip={true}
          />
          <span className="round-score">
            {value.round_scores[lastRoundNum]}
          </span>
        </div>
      ) : (
        <div className="player-bar otherPlayer">
          {/* <div className=
                            {curPlayerStatus.finishedGame
                                ? '' : 'bar-overlay'}></div> */}

          <PlayerAvatar
            user={user}
            state="POST_GAME"
            avatar={curPlayer.avatar}
            name={curPlayer.name}
            id={key}
            hostUID={hostID}
            room={roomID}
            isReady={false}
            isReturned={
              playerStatus[`${curPlayerUID}`]
                ? playerStatus[`${curPlayerUID}`].finishedGame
                : false
            }
            tooltip={true}
          />
          <span className="round-score">
            {playerStatus[`${curPlayerUID}`]
              ? playerStatus[`${curPlayerUID}`].finishedGame
                ? value.round_scores[lastRoundNum]
                : "IN GAME"
              : ""}
          </span>
        </div>
      )}
    </>
  ) : (
    // offline
    <div className="player-bar droppedPlayer">
      <PlayerAvatar
        user={user}
        state="POST_GAME"
        avatar={value.avatar}
        name={value.name}
        id={key}
        hostUID={hostID}
        room={roomID}
        isReady={value.finishedGame}
        isReturned={true}
        tooltip={true}
      />
      <span className="round-score">DROPPED</span>
    </div>
  );
}

function TotalRankingNode({
  user,
  pair,
  lastRoundNum,
  hostID,
  roomID,
  players,
  curPlayerUID,
  playerStatus,
  // nodesEntered is not used but it triggers re-render to display correct status
  nodesEntered,
}) {
  const [key, value] = pair;
  // if useRef is not initialized, gameIndex is undefined.
  // Otherwise, the issue inside the useEffect will occur when one player proceeds to party again.
  const gameIndex = useRef();

  useEffect(() => {
    // save lastRoundNum to useRef() because lastRoundNum (i.e curGame in GameStatsStore)
    // gets incremented when one of the player is ready to proceed
    // SHOULD ONLY RUN ONCE WHEN COMPONENT FIRST LOADED
    // if not, lastRoundNum will be updated, causing earned_scores[lastRoundNum] to be undefined
    gameIndex.current = lastRoundNum;
  }, []);

  return (
    <div className="player-bar">
      <PlayerAvatar
        user={user}
        state="POST_GAME"
        avatar={
          players[`${curPlayerUID}`] ? players[`${curPlayerUID}`].avatar : ""
        }
        name={players[`${curPlayerUID}`] ? players[`${curPlayerUID}`].name : ""}
        id={key}
        hostUID={hostID}
        room={roomID}
        isReady={
          playerStatus[`${curPlayerUID}`]
            ? playerStatus[`${curPlayerUID}`].proceeded
            : false
        }
        isReturned={true}
        tooltip={true}
      />
      <span className="cur-score">{value.total_score}</span>
      <div className="parallel"></div>
      <div className="rect"></div>
      <span className="added-score">
        + {value.earned_scores ? value.earned_scores[gameIndex.current] : ""}
      </span>
    </div>
  );
}

export function RoundRanking({
  user,
  gameStats,
  curGame,
  onRankingSwitch,
  allPlayersLoaded,
  roomID,
  pageEntered,
  online,
  players,
  playerStatus,
}) {
  // pairsRef with useRef allows us to access the latest pairs in setTimeOut()
  const pairsRef = useRef([]);
  // pairs with useState allows the roundranking to be rerendered whenever a player
  // finishes game, so that players can see everyone else's status
  const [pairs, setPairs] = useState([]);
  const sentSwitch = useRef(false);
  const { host } = useRoomInfoStore();

  useEffect(() => {
    console.log("sorting by roundscores, but current user always the first: ");
    let setUpArr = sortPlayersResult(
      gameStats,
      curGame,
      roomID,
      "roundForDisplay",
      user.uid
    );
    // update pairs for display (with the current user being the first)
    setPairs(setUpArr);
    pairsRef.current = setUpArr;

    if (sentSwitch.current) {
      console.log("ready for total ranking, sorting purely by roundscores: ");
      let setUpArr = sortPlayersResult(
        gameStats,
        curGame,
        roomID,
        "round",
        user.uid
      );
      // update pairsRef for onRankingSwitch (purely sorted by roundscores)
      pairsRef.current = setUpArr;
    }

    console.log(pairsRef.current);
  }, [gameStats, curGame, roomID, user]);

  useRoomDataEffect(
    useCallback(
      (data) => {
        // switch to total ranking if everyone is finished
        if (data.room_state.stage === "ranking" && !sentSwitch.current) {
          sentSwitch.current = true;
          setTimeout(() => {
            console.log(
              "switching to total ranking with pairs sorted by round scores: "
            );
            console.log(pairsRef.current);
            allPlayersLoaded();
            onRankingSwitch(pairsRef.current);
          }, 700);
        }
      },
      [onRankingSwitch, allPlayersLoaded, pairsRef]
    ),
    roomID
  );

  return (
    <>
      {" "}
      {pageEntered && (
        <div id="round-ranking" className="ranking">
          {pairs.map((player) => (
            <motion.div
              key={`round-ranking-${player[0]}`}
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              transition={{ duration: 0.7 }}
              className="player-wrapper"
            >
              <RoundRankingNode
                rankClass={player[1].rankClass}
                pair={player}
                user={user}
                lastRoundNum={curGame}
                hostID={host.uid}
                roomID={roomID}
                online={online}
                players={players}
                playerStatus={playerStatus}
                curPlayerUID={player[0]}
              />
            </motion.div>
          ))}
        </div>
      )}
    </>
  );
}

export function TotalRanking({
  user,
  roomID,
  gameStats,
  curGame,
  gameIDs,
  onPageSwitch,
  online,
  players,
  playerStatus,
  // rankingEntered is not used but it triggers re-render to display correct status
  rankingEntered,
  RModalVisible,
}) {
  const [curRoundNum, setRoundNum] = useState(curGame);
  const [pairs, setPairs] = useState([]);
  const { host } = useRoomInfoStore();
  const [nodesEntered, setNodesEntered] = useState(false);

  useEffect(() => {
    if (RModalVisible) {
      console.log("RModalVisible, not displaying total ranking");
      return;
    }
    setPairs(sortPlayersResult(gameStats, curGame, roomID, "total", user));
    console.log("check gameStats in useEffect() in TotalRanking: ", gameStats);
    // console.log('check pairs in TotalRanking: ', pairs)
  }, [gameStats, curGame, roomID, user, RModalVisible]);

  useRoomDataEffect(
    useCallback(
      (data) => {
        // switch to game if everyone is ready
        if (data.room_state.stage === "lobby") {
          setRoundNum(curGame - 1);
        } else {
          setRoundNum(curGame);
        }
      },
      [curGame]
    ),
    roomID
  );

  return RModalVisible ? (
    <></>
  ) : (
    <div className="ranking" id="total-ranking">
      {pairs.map((player) => (
        <motion.div
          key={`total-ranking-${player[0]}`}
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 0.7 }}
          className={`player-wrapper ${player[1].rankClass}`}
          onAnimationComplete={() => setNodesEntered(true)}
        >
          <TotalRankingNode
            key={`total-ranking-${player[0]}$`}
            pair={player}
            user={user}
            lastRoundNum={curRoundNum}
            hostID={host.uid}
            roomID={roomID}
            online={online}
            players={players}
            curPlayerUID={player[0]}
            playerStatus={playerStatus}
            nodesEntered={nodesEntered}
          />
        </motion.div>
      ))}

      <ProceedButton
        onPageSwitch={onPageSwitch}
        roomID={roomID}
        curGame={curRoundNum}
        numGames={gameIDs.length}
        user={user}
      />
    </div>
  );
}

// ----------------------------------------------------------------------------

// avatar: "cat"
// earned_scores: { }
// finishedGame: false
// name: "Nick"
// partyAgain: true
// proceeded: false
// ready: false
// round_rankings: { }
// round_scores: { }
// total_score: 0
// uid: "RnbX4CGYaTUPx2UgbdORaJl0Ivqg"
// __proto__: Object
