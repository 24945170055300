import { realtime_db } from "../services/firebase.js";
import selectRandomAvatar from "../helpers/select-random-avatar.js";
import { ref, get, set, update, child } from "firebase/database";

async function joinRoomByCode(uid, display_name, code) {
  console.log("join room by code: ", uid, display_name, code);
  if (Array.isArray(code)) {
    code = code.join("");
  }

  if (display_name === "") {
    return { success: false, room: code, error: "empty_name" };
  }

  try {
    let roomRef = ref(realtime_db, `rooms/${code}`);

    // get the party_id because it is a lot less data to transfer
    // and can tell us if the room exists
    let room = await get(ref(realtime_db, `rooms/${code}/party_id`));
    if (room && room.exists) {
      let usedAvatars = [];
      let avatar;
      let name_used = false;

      // see if there are players in the room already;
      // check name_used error and set host
      let players = await get(ref(realtime_db, `rooms/${code}/players`));
      if (players && players.exists()) {
        let playersData = players.val();
        Object.keys(playersData).forEach((player_uid) => {
          let curPlayer = playersData[player_uid];
          usedAvatars.push(curPlayer.avatar);
          if (curPlayer.name === display_name && uid !== player_uid) {
            console.log("name used");
            name_used = true;
          }
        });
      } else {
        await update(roomRef, {
          host: { uid: uid, name: display_name },
        });
      }
      if (name_used) {
        return { success: false, room: code, error: "name_used" };
      }

      // communicate cold start
      let cold_starting = true;
      let room_state = await get(child(roomRef, "room_state"));
      console.log("room_state: ", room_state);
      if (room_state && room_state.exists) {
        let stage = await get(child(roomRef, "room_state/stage"));
        console.log("stage: ", stage);
        if (stage && stage.exists && stage.val() === "waiting") {
          cold_starting = false;
        }
      }
      if (cold_starting) {
        console.log("detecting cold start.");
        return { success: false, room: code, error: "cold_start" };
      }

      // pick player avatar
      avatar = selectRandomAvatar(usedAvatars);
      let playerInfo = {
        uid: uid,
        name: display_name,
        avatar: avatar,
      };
      // return player to add player to store when they join room
      let player = {
        [`${uid}`]: playerInfo,
      };
      console.log("picked player avatar: ", avatar);

      // add player info to the players list
      await set(child(roomRef, `players/${uid}`), playerInfo);
      console.log("added player to players in realtime_db");

      // add player status info
      await set(child(roomRef, `playerStatus/${uid}`), {
        ready: false,
        // set proceeded to true so that players in the last party's
        // ranking page could still see the proceeded status of players
        // who have chosen to party again
        proceeded: true,
        partyAgain: true,
        finishedGame: false,
        dropped: false,
        state: "joined",
      });
      console.log("set playerStatus in realtime_db");

      // add game stats
      await set(child(roomRef, `game_stats/${uid}`), {
        total_score: 0,
        round_scores: [],
        round_rankings: [],
        earned_scores: [],
      });
      console.log("set game_stats in realtime_db");
      return { success: true, room: code, player: player };
    } else {
      console.log("no room");
      return { success: false, room: code, error: "no_room" };
    }
  } catch (error) {
    console.log("Oops! Error joining the room.", error);
    return { success: false, room: code, error: "error" };
  }
}

export default joinRoomByCode;
