import React, { useState, useCallback, useEffect } from "react";
import {
  usePageTitleOnMount,
  useToggle,
  useRoomDataEffect,
} from "../../hooks/customHooks.js";
import BackButton from "../../components/BackButton";
import TwoStoryBanner from "../../components/TwoStoryBanner";
import RemovalNotifyModal from "../../components/RemovalNotifyModal";
import SummaryNode from "../../components/SummaryNode";

import joinRoomByCode from "../../helpers/join-room-by-code";
import leaveRoom from "../../helpers/leave-room";

import "./Summary.css";
import { useGameInfoStore } from "../../store/gameInfoStore.js";
import { useGameStatsStore } from "../../store/gameStatsStore.js";
import { usePlayersStore } from "../../store/playersStore.js";
import { useRoomStateDispatch } from "../../store/roomStateStore.js";

function Summary({ onPageSwitch, roomID, user, isMobile }) {
  const { gameIDs } = useGameInfoStore();

  const [sortedPlayerStats, setSortedPlayerStats] = useState([]);
  const [partyStarted, setPartyStarted] = useState(false);
  const [RModalVisible, toggleRModal] = useToggle(false);

  const gameStatsData = useGameStatsStore();
  const dispatchRoomState = useRoomStateDispatch();
  const players = usePlayersStore();

  usePageTitleOnMount("Summary");

  useEffect(() => {
    let sortedPlayersStats = Object.entries(gameStatsData).sort(
      (a, b) =>
        // whoever gets higher total score ranks higher
        b[1].total_score - a[1].total_score
    );
    setSortedPlayerStats(sortedPlayersStats);
    console.log("check gameStatsData useEffect: ", sortedPlayersStats);
  }, [gameStatsData]);

  useRoomDataEffect(
    useCallback(
      (data) => {
        // remove player if new party is started
        if (
          !data.players[user.uid] ||
          (data.room_state.stage === "lobby" &&
            !data.playerStatus[user.uid].partyAgain)
        ) {
          console.log("party started");
          setPartyStarted(true);
          return;
        }
      },
      [user]
    ),
    roomID
  );

  useEffect(() => {
    if (partyStarted && !RModalVisible) {
      leaveRoom(user.uid, roomID);
      toggleRModal();
    }
  });

  function handleRemovalModalSubmit() {
    dispatchRoomState({ type: "LEAVE" });
    onPageSwitch({ state: "HOME", back: true });
  }

  async function backToPartyOnSubmit() {
    const userUID = user.uid;
    let result = await joinRoomByCode(userUID, players[userUID].name, roomID);
    console.log("joinRoomByCode result: ", result);

    if (result.success === true) {
      // update room_state
      const newRoomState = {
        curGame: 0,
        stage: "waiting",
        started: false,
      };
      dispatchRoomState({ type: "INITIALIZE", payload: newRoomState });
      console.log("check move to party lobby");
      onPageSwitch({
        state: "PARTY_LOBBY",
        room: roomID,
        back: false,
      });
      return true;
    }
  }

  return (
    <div className="screen-wrap" id="summary-wrapper">
      <div id="summary" className="page">
        <div id="backToPartyRoom" className="topBar">
          <BackButton id="backToPartyIcon" onClick={backToPartyOnSubmit} />
          <span id="backToPartyInstruction"> BACK TO PARTY ROOM</span>
        </div>

        <TwoStoryBanner text="PARTY SUMMARY" />

        <div id="gameLogos" className="table">
          {gameIDs.map((gameID, index) => (
            <img
              className="gameLogo"
              src={`/img/icons/${gameID}.png`}
              alt={gameID}
              key={index}
            />
          ))}
        </div>

        <div id="player-scores">
          {sortedPlayerStats.map((playerStat) => {
            const playerData = playerStat[1];
            const curPlayerUID = playerStat[0];
            const curPlayer = players[`${curPlayerUID}`];
            const playerProps = {
              uid: curPlayerUID,
              totalScore: playerData.total_score,
              avatar: curPlayer.avatar,
              name: curPlayer.name,
              roundRankings: playerData.round_rankings,
              earnedScores: playerData.earned_scores,
              numGames: gameIDs.length,
            };

            return (
              <SummaryNode key={curPlayerUID} user={user} {...playerProps} />
            );
          })}
        </div>

        <RemovalNotifyModal
          visible={RModalVisible}
          onSubmit={handleRemovalModalSubmit}
        />
      </div>
    </div>
  );
}

export default Summary;
