import React from "react";
import "./Modal.css";
import "./RemovalNotifyModal.css";

function RemovalNotifyModal({ visible, onSubmit }) {
  // const removalP = hostRemove ?
  //     <p className='bodyText' id='removalInstruction'>
  //         You are removed from the party by the host.
  //     </p>
  //     :
  //     <p className='bodyText' id='removalInstruction'>
  //         The party has started. <br></br>
  //         You are removed from the room.
  //     </p>

  return visible ? (
    <div id="removalModal" className="modal">
      <div className="modal-content">
        <p className="bodyText" id="removalInstruction">
          You are removed from the party by the host.
        </p>
        <button className="modalBtn" id="returnHomeButton" onClick={onSubmit}>
          Return to Home
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default RemovalNotifyModal;
