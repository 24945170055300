import React from "react";
import BackButton from "./BackButton";

import "./Banner.css";

function Banner({ onBack, text = "THE BRAIN GAME LAB" }) {
  return (
    <div className="banner js-banner" id="bgl">
      <BackButton onClick={onBack} />
      <img
        id="banner-logo"
        src="/img/logoWhite.png"
        alt="BGL logo"
        height="100%"
        width="auto"
      />
      <p id="banner-p">{text}</p>
    </div>
  );
}

export default Banner;
