import React from "react";

import "./TwoStoryBanner.css";

function TwoStoryBanner({ text }) {
  return (
    <div id="two-story-banner" className="banner">
      <p id="two-story-banner-text" className="h1">
        {" "}
        {text}{" "}
      </p>
    </div>
  );
}

export default TwoStoryBanner;
