import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import {
  getAuth,
  connectAuthEmulator,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAEszaTaEiONwZrDH5hrr9si6sKMT_ANlI",
  authDomain: "the-brain-game-lab.firebaseapp.com",
  databaseURL: "https://the-brain-game-lab.firebaseio.com",
  projectId: "the-brain-game-lab",
  storageBucket: "the-brain-game-lab.appspot.com",
  messagingSenderId: "1006936104896",
  appId: "1:1006936104896:web:4ee050c2997b8126b57225",
  measurementId: "G-S1V8YZHJM8",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const realtime_db = getDatabase(app);

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectDatabaseEmulator(realtime_db, "localhost", 9000);
  // if running localhost, then link anon accounts to the browser
  // tab so we can debug by opening multiple tabs.
  setPersistence(auth, browserSessionPersistence);
}
