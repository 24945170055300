import React, { useState, useCallback, useContext, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import "../../App.css";
import LoadingScreen from "../loading/loading";
import Home from "../Home/Home";
import PartyLobby from "../PartyLobby/PartyLobby";
import GameLobby from "../GameLobby/GameLobby";
import Game from "../Game/Game";
// eslint-disable-next-line no-unused-vars
import PostGame from "../PostGame/PostGame";
import Summary from "../Summary/Summary";
import { UserContext } from "../../contexts/UserContext";

import { isMobile } from "react-device-detect"; // is mobile: mobile/tablet
import { realtime_db } from "../../services/firebase";

import { set, off, ref, onValue, onDisconnect } from "firebase/database";

function Play() {
  const { user, setUser } = useContext(UserContext);
  const [back, setBack] = useState(false); // todo
  const [appState, setAppState] = useState({
    page: "HOME",
    room: null,
    game: null,
  });
  const [pageEntered, setPageEntered] = useState(false);

  // memoized this since it is used as a dependency a few times
  const startPageSwitch = useCallback((pageSwitchData) => {
    setAppState({
      page: pageSwitchData.state,
      room: pageSwitchData.room,
      game: pageSwitchData.gameID,
      // loading only props:
      uid: pageSwitchData.uid,
      username: pageSwitchData.username,
    });
    if (pageSwitchData.back) {
      setBack(true);
    } else {
      setBack(false);
    }
  }, []);

  function renderSwitch(pageState) {
    const PageProps = {
      onPageSwitch: startPageSwitch,
      user: user,
      isMobile: isMobile,
      roomID: pageState.room,
      gameID: pageState.game,
      pageEntered: pageEntered,
      // for loading page
      uid: pageState.uid,
      username: pageState.username,
    };
    console.log(PageProps);
    switch (pageState.page) {
      case "LOADING":
        return <LoadingScreen {...PageProps} />;
      case "HOME":
        return <Home {...PageProps} />;
      case "PARTY_LOBBY":
        return <PartyLobby {...PageProps} />;
      case "GAME_LOBBY":
        return <GameLobby {...PageProps} />;
      case "GAME":
        return <Game {...PageProps} />;
      case "POST_GAME":
        return <PostGame {...PageProps} />;
      case "SUMMARY":
        return <Summary {...PageProps} />;
      default:
        return <></>;
    }
  }

  useEffect(() => {
    if (user && appState.room && appState.page !== "LOADING") {
      //const reference = realtime_db.ref(`/online/${appState.pageState.room}/${user.uid}`);
      const droppedReference = ref(realtime_db,
        `/rooms/${appState.room}/playerStatus/${user.uid}/dropped`
      );

      const playersReference = ref(realtime_db, `/rooms/${appState.room}/players`);

      onValue(playersReference, (snapshot) => {
        if (snapshot.hasChild(user.uid)) {
          set(droppedReference, false);
          onDisconnect(droppedReference).set(true);
        }
        off(playersReference);
      });
      // realtime_db
      //   .ref(`/rooms/${appState.room}/players`)
      //   .once("value")
      //   .then(function (snapshot) {
      //     if (snapshot.hasChild(user.uid)) {
      //       // Set the /users/:userId value to true
      //       droppedReference.set(false).then(() => console.log("Online presence set"));

      //       // Remove the node whenever the client disconnects
      //       droppedReference
      //         .onDisconnect()
      //         .set(true)
      //         .then(() => console.log("On disconnect function configured."));
      //     } else {
      //       console.log(`${user.uid} is not in players. removed?`);
      //     }
      //   });
    }
  }, [user, appState.room, appState.page]);

  const variants = {
    // if the transition happens when the user clicks 'back' button,
    // then slide in new page from the left
    // otherwise, slide in from the right
    enter: (back) => {
      return {
        x: back ? "100%" : "100%",
        y: "0",
      };
    },
    center: {
      x: 0,
      y: 0,
    },
    exit: (back) => {
      return {
        x: back ? "-100%" : "-100%",
        y: "0",
      };
    },
  };

  return (
    <AnimatePresence custom={back} exitBeforeEnter initial={false}>
      <motion.div
        key={appState.page}
        custom={back}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.7, ease: "easeIn" }}
        onAnimationComplete={() => setPageEntered(true)}
      >
        {renderSwitch(appState)}
      </motion.div>
    </AnimatePresence>
  );
}

export default Play;
