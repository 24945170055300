import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";

import "./App.css";
import { AnimatePresence, motion } from "framer-motion";

import About from "./pages/About/About";
import Profile from "./pages/Profile/Profile";
import Play from "./pages/Play/Play";
import { db } from "./services/firebase";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
// import Navbar from './components/Navbar/Navbar';
import { UserContext } from "./contexts/UserContext";
import { PartyContext } from "./contexts/PartyContext";
import { RoomStateProvider } from "./store/roomStateStore";
import { PlayersProvider } from "./store/playersStore";
import { GameInfoProvider } from "./store/gameInfoStore";
import { GameStatsProvider } from "./store/gameStatsStore";
import { RoomInfoProvider } from "./store/roomInfoStore";

function App() {
  const [user, setUser] = useState();
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const [partyState, setPartyState] = useState({
    hasStarted: false,
  });
  const partyValue = useMemo(
    () => ({ partyState, setPartyState }),
    [partyState, setPartyState]
  );

  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        saveUserNameEmail(user);
      }
    });
  });

  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        console.log("user signed in");
      })
      .catch((error) => {
        console.log(error.code, error.message);
      });
  }, []);

  const saveUserNameEmail = async (user) => {
    const userDoc = doc(db, "users", user.uid);
    const userDocRef = await getDoc(userDoc);
    if (!userDocRef.exists) {
      try {
        await setDoc(userDoc, {
          name: user.displayName ? user.displayName : "",
          email: user.email ? user.email : "",
        });
      } catch (error) {
        console.log("Error when setting user doc: ", error);
      }
    }
  };

  function renderApp() {
    return (
      <UserContext.Provider value={userValue}>
        <PartyContext.Provider value={partyValue}>
          <RoomStateProvider>
            <PlayersProvider>
              <GameInfoProvider>
                <GameStatsProvider>
                  <RoomInfoProvider>
                    {/* <Navbar /> */}
                    <Switch>
                      <Route exact path="/" component={Play} />
                      <Route exact path="/about" component={About} />
                      <Route exact path="/profile" component={Profile} />
                    </Switch>
                  </RoomInfoProvider>
                </GameStatsProvider>
              </GameInfoProvider>
            </PlayersProvider>
          </RoomStateProvider>
        </PartyContext.Provider>
      </UserContext.Provider>
    );
  }

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div
        key={"app"}
        initial={{ x: "100%", y: 0 }}
        animate={{ x: 0, y: 0 }}
        exit={{ x: "-100%", y: 0 }}
        transition={{ duration: 0.3, ease: "easeIn" }}
      >
        {renderApp()}
      </motion.div>
    </AnimatePresence>
  );
}

export default App;
