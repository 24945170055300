/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { db, realtime_db } from "../../services/firebase";
import {
  useToggle,
  usePageTitleOnMount,
  useRealtimeDatabaseEntry,
  useRoomDataEffect,
} from "../../hooks/customHooks";
import Banner from "../../components/Banner.js";
import ExitModal from "../../components/ExitModal.js";
import PlayerAvatar from "../../components/PlayerAvatar.js";
import "./PartyLobby.css";

import leaveRoom from "../../helpers/leave-room.js";
import {
  useRoomStateDispatch,
  useRoomStateStore,
} from "../../store/roomStateStore";
import { usePlayersDispatch } from "../../store/playersStore";
import { useGameInfoDispatch } from "../../store/gameInfoStore";
import { useRoomInfoDispatch } from "../../store/roomInfoStore";
import RemovalNotifyModal from "../../components/RemovalNotifyModal";
import { get, child, update, set, onValue, ref } from "firebase/database";
import { doc, setDoc } from "firebase/firestore";

function PartyLobby({ user, roomID, onPageSwitch, pageEntered, isMobile }) {
  const [startWithText, setStartWithText] = useState("WITH 1 PLAYERS");
  const [visible, toggleModal] = useToggle();
  const [waitingPlayers, setWaitingPlayers] = useState({});
  const [playerStatus, setPlayerStatus] = useState({});
  const hostUID = useRealtimeDatabaseEntry(`/rooms/${roomID}/host/uid`);
  const partyId = useRealtimeDatabaseEntry(`/rooms/${roomID}/party_id`);
  const gameIDs = useRealtimeDatabaseEntry(`/rooms/${roomID}/gameInfo/gameIDs`);

  const dispatchRoomState = useRoomStateDispatch();
  const dispatchGameInfo = useGameInfoDispatch();
  const dispatchPlayers = usePlayersDispatch();
  const dispatchRoomInfo = useRoomInfoDispatch();

  const { curGame, started } = useRoomStateStore();

  const [RModalVisible, toggleRModal] = useState(false);

  useRoomDataEffect(
    useCallback(
      (data) => {
        // remove player if not in the party
        if (!data.players[user.uid] && !RModalVisible) {
          console.log("player removed");
          toggleRModal(true);
          return;
        }

        // initialize data in stores
        dispatchRoomInfo({
          type: "INITIALIZE",
          payload: {
            partyId: data.party_id,
            host: data.host,
          },
        });
        dispatchGameInfo({ type: "INITIALIZE", payload: data.gameInfo });
        dispatchRoomState({ type: "INITIALIZE", payload: data.room_state });
        setPlayerStatus(data.playerStatus);
        setWaitingPlayers(data.players);

        // calculate number of players
        let numPlayers = 0;
        // if people are returning from a previous party,
        // numPlayer = players who have chosen to party again
        if (data.lastPartyData) {
          numPlayers = Object.values(data.playerStatus).filter((pdata) => {
            // console.log(pdata);
            return pdata.partyAgain;
          }).length;
        } else {
          // 1st party in this room -> numPlayer = number of players entered the room
          numPlayers = Object.keys(data.players).length;
        }
        setStartWithText(`WITH ${numPlayers} PLAYERS`);
        dispatchPlayers({ type: "INITIALIZE", payload: data.players });
      },
      [dispatchRoomInfo, dispatchGameInfo, dispatchRoomState, dispatchPlayers]
    ),
    roomID
  );

  function handleRemovalModalSubmit() {
    dispatchRoomState({ type: "LEAVE" });
    onPageSwitch({ state: "HOME", back: true });
  }

  // TODO: Separating out all these entries may not work, because they sync at different times
  // and cause multiple renders.
  // This creates problems when waitingPlayers doesn't match playerStatus, for example.
  // Might need to design the database so that any linked information is together
  // and updated at the same time here.

  async function handleStartParty() {
    console.log("host starting party");
    let updatedRoomData = await updateRoomDataOnStartParty();
    let isInit = await initUserPartyDoc();
    if (updatedRoomData && isInit) {
      onPageSwitch({
        state: "GAME_LOBBY",
        room: roomID,
        back: false,
      });
    }
  }

  async function updateRoomDataOnStartParty() {
    const roomRef = ref(realtime_db, `rooms/${roomID}`);
    try {
      const room = await get(roomRef);
      if (room && room.exists) {
        // updating reParty
        if (room.hasChild("lastPartyData")) {
          try {
            await update(child(roomRef, "room_state"), {
              reParty: true,
            });
            console.log("reParty is true");
          } catch (error) {
            console.log("error updating reParty", error);
            return false;
          }
        }
        // clearing lastPartyData on start
        try {
          await update(roomRef, {
            lastPartyData: null,
          });
          await update(child(roomRef, "room_state"), {
            stage: "lobby",
            started: true,
          });
          return true;
        } catch (error) {
          console.log(
            "Something went wrong with clearing lastPartyData and setting uproom_state."
          );
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.log("Oops! Something went wrong with starting the party.");
      return false;
    }
  }

  async function initUserPartyDoc() {
    const partyDoc = doc(db, `users/${user.uid}/parties/${partyId}`);

    try {
      await setDoc(partyDoc, {
        isHost: user.uid === hostUID,
        other_players: [],
      });
      return true;
    } catch (error) {
      console.log("Error when setting user party doc: ", error);
      return false;
    }
  }

  function handleExitModalSubmit() {
    toggleModal();
    leaveRoom(user.uid, roomID);
    onPageSwitch({ state: "HOME", back: true });
  }

  usePageTitleOnMount("Waiting Room");

  useEffect(() => {
    if (started && user.uid !== hostUID) {
      console.log("Host has started party. Moved to GameLobby");
      onPageSwitch({
        state: "GAME_LOBBY",
        room: roomID,
        gameID: gameIDs[curGame],
        back: false,
      });
    }
  }, [started, curGame, gameIDs, hostUID, onPageSwitch, roomID, user.uid]);

  return (
    <div className="screen-wrap" id="waiting-room-wrapper">
      <div className="page" id="waiting-room">
        <Banner onBack={toggleModal} />

        <div id="roomInfo">
          <p className="h2" id="party-room">
            {" "}
            PARTY ROOM
          </p>
          <p id="room-code" className="h1">
            {roomID}
          </p>
          <p id="ask-friends" className="instructionText">
            Ask your friends to enter the code and join the party!
          </p>
        </div>

        <div id="players-waiting-area">
          {Object.keys(waitingPlayers)
            .filter((a) => Object.keys(playerStatus).includes(a))
            .map((x) => (
              <PlayerAvatar
                user={user}
                state="PARTY_LOBBY"
                key={x}
                avatar={waitingPlayers[x].avatar}
                name={waitingPlayers[x].name}
                id={x}
                hostUID={hostUID}
                room={roomID}
                tooltip={false}
                isReady={false}
                isReturned={playerStatus[x].partyAgain}
              />
            ))}
        </div>

        <button
          id="interaction"
          onClick={() => {
            if (user.uid === hostUID) {
              handleStartParty();
            }
          }}
        >
          {user.uid === hostUID ? (
            <div className="host" id="startPartyButton">
              <p id="startPartyText"> START PARTY </p>
              <p id="startWithText"> {startWithText} </p>
            </div>
          ) : (
            <p className="guest instructionText" id="waitingText">
              Waiting for the host to start the party...
            </p>
          )}
        </button>

        <ExitModal
          visible={visible}
          onClose={toggleModal}
          onSubmit={handleExitModalSubmit}
        />

        <RemovalNotifyModal
          visible={RModalVisible}
          onSubmit={handleRemovalModalSubmit}
        />
      </div>
    </div>
  );
}

export default PartyLobby;
