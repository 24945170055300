import React from "react";
import { useRealtimeDatabaseEntry } from "../hooks/customHooks";
import leaveRoom from "../helpers/leave-room.js";
import BGLTooltip from "./BGLTooltip";
import "./PlayerAvatar.css";

// user: current user (aht)
// id: the uid of this avatar
// hostUID: uid of the host in the party
function PlayerAvatar({
  user,
  state,
  avatar,
  name,
  id,
  hostUID,
  room,
  isReady,
  tooltip,
  isReturned,
}) {
  const online = !useRealtimeDatabaseEntry(
    `/rooms/${room}/playerStatus/${user.uid}/dropped`
  );
  const isHost = hostUID === id;
  const hostClass = isHost ? "player host" : "player guest";
  const stateClass = state === ("POST_GAME" || "SUMMARY") ? "barNode" : "";

  async function kickPlayer() {
    console.log("kicking player");
    leaveRoom(id, room);
  }

  return online ? (
    <>
      <div
        className={`${hostClass} ${stateClass}`}
        data-tip
        data-for={`tooltip-${name}`}
      >
        <div className="avatarDiv">
          {user.uid === hostUID && hostUID !== id ? (
            <button
              type="button"
              className="kickBtn"
              aria-label="kick"
              onClick={kickPlayer}
            >
              <span aria-hidden="true" id="closeIcon">
                &times;
              </span>
            </button>
          ) : (
            <></>
          )}
          <img
            className="ready-status"
            id={isReady ? "" : "ready-status-hidden"}
            src="img/icons/green-correct.png"
            alt="isReady"
          />
          <img
            className="return-status"
            id={isReturned ? "waiting-status-hidden" : ""}
            src="img/icons/waiting.svg"
            alt="notReturned"
          />
          <div className={isReady || !isReturned ? "overlay" : ""}></div>
          {avatar ? (
            <img
              alt={avatar}
              src={`/img/avatars/${avatar}.png`}
              className={user.uid === id ? "current-user" : "other-user"}
            />
          ) : (
            <></>
          )}
        </div>
        {state === "PARTY_LOBBY" && !tooltip ? (
          <span className={isHost ? "hostName" : ""} id="username-text">
            {name}
          </span>
        ) : (
          <></>
        )}
      </div>
      {tooltip ? <BGLTooltip display={name} /> : <></>}
    </>
  ) : (
    // offline
    <>
      {state === "POST_GAME" ? (
        <div
          className={`${hostClass} ${stateClass}`}
          data-tip
          data-for="registerTip"
        >
          <div className="avatarDiv">
            {user.uid === hostUID && hostUID !== id ? (
              <button
                type="button"
                className="kickBtn"
                aria-label="kick"
                onClick={kickPlayer}
              >
                <span aria-hidden="true" id="closeIcon">
                  &times;
                </span>
              </button>
            ) : (
              <></>
            )}
            <img
              className="offline-status"
              src="img/icons/offline.png"
              alt="isDropped"
            />
            <div className="overlay"></div>
            <img
              alt={avatar}
              src={`/img/avatars/${avatar}.png`}
              className={user.uid === id ? "current-user" : "other-user"}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default PlayerAvatar;
