import React from "react";
import CloseButton from "./CloseButton";

import "./Modal.css";

function ExitModal({ visible, onClose, onSubmit }) {
  return visible ? (
    <div id="exit" className="modal">
      <div id="model-content-color" className="modal-content">
        <CloseButton onClose={onClose} />
        <div id="guest-login">
          <p
            id="leave-body-text"
            className="bodyText"
            style={{ fontSize: "18px", textAlign: "center" }}
          >
            Leave the party?
          </p>
          <div style={{ textAlign: "center" }}>
            <button onClick={onSubmit} className="modalBtn">
              LEAVE
            </button>
            <button onClick={onClose} className="modalBtn">
              STAY
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default ExitModal;
