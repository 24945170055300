import React, { useState, useEffect } from "react";
import "./Iframe.css";

// credit goes to Stackoverflow user Lukas Bünger for part of this component
function IFrame({ title, contentWindowHandler, ...props }) {
  const [contentRef, setContentRef] = useState(null);

  useEffect(() => {
    if (contentWindowHandler) {
      contentWindowHandler(contentRef?.contentWindow);
    }
  }, [contentWindowHandler, contentRef]);

  return (
    <iframe
      title={title}
      {...props}
      ref={setContentRef}
      background="transparent"
    />
  );
}

export default IFrame;
