function sortPlayersResult(
  gameStatsDict,
  curGame,
  roomID,
  rankingType,
  userUID
) {
  console.log("check ranking type: ", rankingType);

  // pairs: player index (key) + players uid and score info dict (value)
  let pairs = Object.entries(gameStatsDict);
  console.log("check pairs: ", pairs);

  // num players > 0 && player has some info
  if (pairs.length > 0 && pairs[0].length > 0) {
    pairs.sort((a, b) => {
      if (rankingType === "total") {
        // higher total score goes before lower total score
        return b[1].total_score - a[1].total_score;
      } else if (rankingType === "round") {
        // purely based on round ranking, regardless of being the current user
        // -> for updating RET
        return b[1].round_scores[curGame] - a[1].round_scores[curGame];
      } else {
        // current user always the first
        // -> for display purpose in round ranking
        // if (a[0] === userUID) return -1;
        // if (b[0] === userUID) return 1;

        // check to make sure round_scores exists
        if (!a[1].round_scores || !a[1].round_scores[curGame]) {
          return 1;
        }
        if (!b[1].round_scores || !b[1].round_scores[curGame]) {
          return -1;
        }

        return b[1].round_scores[curGame] - a[1].round_scores[curGame];
      }
    });
    console.log("check pairs sort scores: ", pairs);
  }

  if (rankingType === "total") {
    return assignRankBasedOnTotalScore(pairs, curGame);
  } else {
    let allPlayerHasData = true;
    let numProperties = 0;
    /*
        Since realtime db does not allow initializing property with empty array/object
        when a player has not yet finished while others have, then the property
        round_scores and rankClass for that player under 'game_stats' would not be initialize,
        thus calling assignRankBasedOnRoundScore() now would return error
        */
    pairs.forEach((pair) => {
      // pair[0] is player uid; pair[1] is player score info dict
      // playerNumProperties - number of score info pieces
      const playerNumProperties = Object.keys(pair[1]).length;
      if (playerNumProperties !== numProperties) {
        allPlayerHasData = false;
      }
    });
    if (allPlayerHasData) {
      return assignRankBasedOnRoundScore(pairs, curGame);
    } else {
      return pairs;
    }
  }
}

// playersStatsArr is a 2D array [[<uid>, <stats],...] already sorted by round score
function assignRankBasedOnRoundScore(playersStatsArr, curGame) {
  console.log("assigning rank based on round scores");
  for (let i = 0; i < playersStatsArr.length; i++) {
    console.log("check thisData: ", playersStatsArr[i][1]);

    let thisData = playersStatsArr[i][1];
    thisData.rankClass = "noCrown";

    // if this score is 0, no need to check players worse than this
    if (
      !thisData.round_scores[curGame] ||
      thisData.round_scores[curGame] === 0
    ) {
      break;
    }
    // if this player is the first and not scoring 0,
    // first rank guaranteed
    if (i === 0) {
      console.log("i=0");
      thisData.curRank = 0;
      thisData.rankClass = "firstRank";
      continue;
    }

    let prevData = playersStatsArr[i - 1][1];
    if (thisData.round_scores[curGame] === prevData.round_scores[curGame]) {
      thisData.curRank = prevData.curRank;
    } else {
      thisData.curRank = i;
    }
    assignRankClassToPlayerBasedOnRank(thisData);
  }
  return playersStatsArr;
}

// playersStatsArr is a 2D array [[<uid>, <stats],...] already sorted by total score
function assignRankBasedOnTotalScore(playersStatsArr, curGame) {
  for (let i = 0; i < playersStatsArr.length; i++) {
    console.log("check thisData: ", playersStatsArr[i][1]);

    let thisData = playersStatsArr[i][1];
    thisData.rankClass = "noCrown";

    // rankingType==='total'
    // if this score is 0, no need to check players worse than this
    if (thisData.total_score === 0) {
      break;
    }
    // if this player is the first and not scoring 0,
    // first rank guaranteed
    if (i === 0) {
      thisData.curRank = 0;
      thisData.rankClass = "firstRank";
      continue;
    }

    let prevData = playersStatsArr[i - 1][1];
    if (thisData.total_score === prevData.total_score) {
      thisData.curRank = prevData.curRank;
    } else {
      thisData.curRank = i;
    }
    assignRankClassToPlayerBasedOnRank(thisData);
  }
  return playersStatsArr;
}

function assignRankClassToPlayerBasedOnRank(playerStats, curGame) {
  switch (playerStats.curRank) {
    case 0:
      playerStats.rankClass = "firstRank";
      break;
    case 1:
      playerStats.rankClass = "secondRank";
      break;
    case 2:
      playerStats.rankClass = "thirdRank";
      break;
    default:
      playerStats.rankClass = "noCrown";
      break;
  }
}

export default sortPlayersResult;
