import React, { useState } from "react";
import "./Modal.css";
import "./NameEntryModal.css";

function NameEntryModal({ visible, onClose, onSubmit, errormsg }) {
  const [name, setName] = useState("");

  if (visible) {
    return (
      // can we combine the two divs below?
      <div id="login" className="modal">
        <div className="modal-content">
          <button
            className="close"
            id="closeButton"
            onClick={() => {
              onClose();
            }}
          >
            &times;
          </button>
          <div id="guest-login">
            <div className="info-input" id="name">
              <input
                type="text"
                value={name}
                id="guest-name"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <p id="errormsg">{errormsg}</p>
            <button
              id="anon-login-submit"
              onClick={() => {
                onSubmit(name);
              }}
            >
              JOIN
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default NameEntryModal;
