import React from "react";
import "./SummaryNode.css";

function SummaryNode({
  user,
  uid,
  totalScore,
  avatar,
  name,
  roundRankings,
  earnedScores,
  numGames,
}) {
  // limit of 5 ppl max
  const greenShades = [
    "#0A2A1D",
    "#0F432D",
    "#176444",
    "#1C7C54",
    "#449372",
    "#449372",
    "#449372",
    "#449372",
  ];

  return (
    <div className="summary_player-bar" id={uid}>
      <span className="summary_total-score h2">{totalScore} </span>
      <img
        className={`summary_avatar ${user.uid === uid ? "current-user" : ""}`}
        src={`/img/avatars/${avatar}.png`}
        alt={name}
      />
      <div className="summary_table">
        {Object.keys(earnedScores).map((roundNum, index) => {
          let curRanking = roundRankings[roundNum];
          let bgClr = greenShades[curRanking];
          if (earnedScores[roundNum] === 0) {
            bgClr = greenShades[greenShades.length - 1];
          }

          let scoreDivStyle = {
            backgroundColor: bgClr,
            width: `calc((min(100vw, 720px) - 130px)/${numGames})`,
          };

          return (
            <span
              className="summary_round-score bodyText"
              key={index}
              style={scoreDivStyle}
            >
              {earnedScores[roundNum]}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default SummaryNode;
