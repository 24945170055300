import { useReducer, useRef, useEffect, useState } from "react";
import { db, realtime_db } from "../services/firebase.js";
import { ref, onValue, off } from "firebase/database";

// returns [boolean, function() ]
// function toggles the boolean
export function useToggle() {
  return useReducer((visible) => !visible, false);
}

// takes in useCallback(func(data)), roomID
// if you don't use useCallback, the useEffect will be called every rerender
// func is called with data as it's parameter
export function useRoomDataEffect(func, roomID, path) {
  if (typeof path === "undefined") {
    path = "";
  }
  if (!path.startsWith("/")) {
    path = "/" + path;
  }

  const snapshotHandler = useRef(null);

  useEffect(() => {
    const roomRef = ref(realtime_db, `rooms/${roomID}${path}`);
    snapshotHandler.current = onValue(roomRef, (room) => {
      const data = room.val();
      if (data) {
        func(data);
      }
    });
    return snapshotHandler.current;
  }, [func, roomID, path]);
}

export function useRealtimeDatabaseEntry(entry) {
  const [data, setData] = useState({});

  useEffect(() => {
    const db_ref = ref(realtime_db, entry);
    let isRendered = true;
    onValue(db_ref, (snapshot) => {
      if (isRendered) {
        const db_data = snapshot.val();
        setData(db_data);
        return db_data;
      }
    });
    // return a cleanup function
    return () => {
      off(db_ref);
      isRendered = false;
    };
  }, [entry]);
  return data;
}

// adds page title on component mount
export function usePageTitleOnMount(title) {
  useEffect(() => {
    document.title = title;
  }, [title]);
}
