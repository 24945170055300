import { realtime_db } from "../services/firebase.js";

async function leaveRoom(uid, code) {
  let roomRef = realtime_db.ref("rooms/" + code);
  // let activeRoomsRef = realtime_db.ref('active_rooms');

  try {
    let hostInfo = await roomRef.child("host").get();
    let hostData = hostInfo.val();
    let playersInfo = await roomRef.child("players").get();
    let playersData = playersInfo.val();

    if (hostInfo && hostInfo.exists() && playersData[uid]) {
      // check if this person leaving will empty the room
      if (Object.keys(playersData).length === 1) {
        // it will, so just remove the room from the database
        await roomRef.remove();
        // TODO: also remove from active_rooms
      } else {
        // remove the player from the players and playerStatus
        try {
          await roomRef.child(`players/${uid}`).remove();
        } catch (error) {
          console.log(`Oops! Error deleting player ${uid} info: `, error);
        }
        try {
          await roomRef.child(`playerStatus/${uid}`).remove();
        } catch (error) {
          console.log(`Oops! Error deleting player ${uid} status: `, error);
        }
        try {
          await roomRef.child(`game_stats/${uid}`).remove();
        } catch (error) {
          console.log(`Oops! Error deleting player ${uid} status: `, error);
        }

        if (hostData.uid === uid) {
          let roomPlayersUID = Object.keys(playersData).filter((x) => {
            return x !== uid;
          });

          let newHostID = roomPlayersUID[0];

          let newHost = {
            name: playersData[newHostID].name,
            uid: newHostID,
          };

          console.log("host leaving, assigned new host: ", newHost);
          try {
            await roomRef.child("host").set(newHost);
          } catch (error) {
            console.log("Error updating the host", error);
            return false;
          }
        }
      }
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Oopsie doopsie!", error);
  }
}

// async function removePlayerFromRoom() {
//     try {
//       const roomRef = realtime_db.ref(`rooms/${roomID}`);
//       const room = await roomRef.get();
//       if (room && room.exists) {
//         let roomref = `rooms/${roomID}`;
//         firebase.database().ref(`${roomref}/players/${user.uid}`).remove();
//         firebase.database().ref(`${roomref}/playerStatus/${user.uid}`).remove();
//         firebase.database().ref(`${roomref}/game_stats/${user.uid}`).remove();
//         console.log('deleted the unreturned player');
//         return true;
//       } else {
//         return false;
//       }
//     } catch (error) {
//       console.log('Something went wrong with deleting the unreturned player.'
//         , error);
//     }
//   }

export default leaveRoom;
