import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  useRoomDataEffect,
  usePageTitleOnMount,
  useToggle,
  useRealtimeDatabaseEntry,
} from "../../hooks/customHooks.js";
import { db, realtime_db } from "../../services/firebase.js";
import TwoStoryBanner from "../../components/TwoStoryBanner";
import { TotalRanking, RoundRanking } from "../../components/Ranking.js";
import { AnimatePresence, motion } from "framer-motion";
import RemovalNotifyModal from "../../components/RemovalNotifyModal";

import leaveRoom from "../../helpers/leave-room.js";

import "./PostGame.css";
import {
  useRoomStateStore,
  useRoomStateDispatch,
} from "../../store/roomStateStore.js";
import { usePlayersStore } from "../../store/playersStore.js";
import { useGameInfoStore } from "../../store/gameInfoStore.js";
import { useRoomInfoStore } from "../../store/roomInfoStore.js";

import { ref, onValue, get, child, update, set, off } from "firebase/database";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

function PostGame({ onPageSwitch, roomID, user, pageEntered, isMobile }) {
  const [partyStarted, setPartyStarted] = useState(false);
  const [RModalVisible, toggleRModal] = useState(false);

  const { partyId } = useRoomInfoStore();

  let roomDataRef = ref(realtime_db, `/rooms/${roomID}`);
  if (!RModalVisible) {
    onValue(roomDataRef, (snapshot) => {
      if (snapshot.val().lastPartyData) {
        console.log("using last party data");
        off(roomDataRef);
        roomDataRef = ref(realtime_db, `/rooms/${roomID}/lastPartyData`);
      }
    });
  }
  const gameStats = useRealtimeDatabaseEntry(
    `${roomDataRef
      .toString()
      .substring(roomDataRef.root.toString().length - 1)}/game_stats`
  );

  // lastPartyData only matters for game_stats(player game data)
  // we need to keep player status updated, and curGame won't affect anything.
  const playerStatus = useRealtimeDatabaseEntry(
    `/rooms/${roomID}/playerStatus/`
  );
  const curGameIndex = useRealtimeDatabaseEntry(
    `/rooms/${roomID}/room_state/curGame`
  );

  const online = !useRealtimeDatabaseEntry(
    `/rooms/${roomID}/playerStatus/${user.uid}/dropped`
  );
  const [waitingStatus, setWaitingStatus] = useState(
    "Waiting for all players to finish..."
  );
  const [curRanking, setCurRanking] = useState("ROUND");
  const [totalRankingEntered, setTotalRankingEntered] = useState(false);
  // // eslint-disable-next-line no-unused-vars
  const gameIndex = useRef();
  gameIndex.current = curGameIndex;

  const { curGame } = useRoomStateStore();
  const players = usePlayersStore();
  const { gameIDs } = useGameInfoStore();
  const dispatchRoomState = useRoomStateDispatch();

  usePageTitleOnMount("Leaderboard");

  useRoomDataEffect(
    useCallback(
      (data) => {
        // setCurGame(data.room_state.curGame)
        // remove player if not in the party
        if (!data.players[user.uid] && !RModalVisible) {
          console.log("player removed");
          toggleRModal(true);
          return;
        }
        // remove player if new party is started
        if (
          data.room_state.reParty &&
          !data.playerStatus[user.uid].partyAgain &&
          !RModalVisible
        ) {
          // console.log('is player in players? ', data.players[user.uid])
          // console.log('reparty? ', data.room_state.reParty)
          // console.log('player partyagain? ', data.playerStatus[user.uid].partyAgain )
          // console.log('removal modal visible? ', RModalVisible)
          console.log("party started");
          toggleRModal(true);
          leaveRoom(user.uid, roomID);
          console.log("other players have started party. removed user");
          return;
        }
      },
      [user, RModalVisible, roomID, toggleRModal]
    ),
    roomID
  );

  // useEffect(()=>{
  //   if (partyStarted && !RModalVisible){
  //     console.log('other players have started party. need to remove user')
  //     leaveRoom(user.uid, roomID);
  //     toggleRModal();
  //   }
  // });

  // RET: ranking, earnedScore, totalScore
  async function updateRET(pairs) {
    console.log("updating RET on pairs (sorted by round scores): ", pairs);
    // let curGame = gameIndex.current;
    // compute earned score
    let scoreInterval = 2; // difference in score between two rankings
    // determine the highest ranking's score based on number of players in total
    let maxScore = pairs.length * scoreInterval;
    for (let i = 0; i < pairs.length; i++) {
      let [curUID, curPairData] = pairs[i];

      // if (curPairData.earned_scores && curPairData.earned_scores.length > curGame){
      //   // if we have already updated this player's RET (we have earned_score for curGame)
      //   // then don't update again
      //   console.log(`already updated RET for ${curUID} - we have their earned score`)
      //   continue
      // }

      let curRoundScore = curPairData.round_scores[`${curGame}`];
      // update ranking
      let curRoundRanking = i;
      // assign same ranking as prev player if they have the same round score
      if (i > 0) {
        let lastPairData = pairs[i - 1][1];
        let lastRoundScore = lastPairData.round_scores[`${curGame}`];
        if (
          curRoundScore === lastRoundScore &&
          pairs[i - 1][1].round_rankings
        ) {
          curRoundRanking = pairs[i - 1][1].round_rankings[`${curGame}`];
        }
      }
      // also change the data in the pairs array for later iterations' reference
      // console.log('check curPairData: ', curPairData)
      // pairs[i][1].round_rankings = {}
      if (curPairData.round_rankings)
        curPairData.round_rankings[`${curGame}`] = curRoundRanking;

      // update earned scores
      let curEarnedScore = maxScore - curRoundRanking * scoreInterval;
      if (curRoundScore === 0 || curRoundScore === undefined) {
        curEarnedScore = 0;
      }
      // pairs[i][1].earned_scores = {}
      if (!curPairData.earned_scores) curPairData.earned_scores = [];
      curPairData.earned_scores[`${curGame}`] = curEarnedScore;

      // update total scores
      // calculate total score by adding up all earned score
      // avoid calling updateRET multiple times and get extra total score
      let curTotalScore = curPairData.earned_scores.reduce(
        (sum_acc, earned_score) => sum_acc + earned_score,
        0
      );
      curPairData.total_score = curTotalScore;

      console.log(`RET - updated for ${curUID}: `, curPairData);

      // only update realtime db of the auth
      if (user.uid !== curUID) {
        continue;
      }

      // update user gamestats in realtime db
      let roomRef = ref(realtime_db, `rooms/${roomID}`);
      try {
        let userGameStatsRef = child(roomRef, `game_stats/${curUID}`);
        let userGameStats = await get(userGameStatsRef);
        if (userGameStats && userGameStats.exists) {
          update(userGameStatsRef, {
            [`/round_rankings/${curGame}`]: curRoundRanking,
            [`/earned_scores/${curGame}`]: curEarnedScore,
            total_score: curTotalScore,
          });
          console.log("updated earned score in db for auth user");
        }
      } catch (error) {
        console.log("Error getting roomRef:", error);
      }

      // update in Users collection
      try {
        const userPartyDoc = doc(db, `users/${user.uid}/parties/${partyId}`);

        await setDoc(doc(userPartyDoc, `gameStats/${gameIDs[curGame]}`), {
          score: curRoundScore,
        });
      } catch (error) {
        console.log(
          "Oops! Something went wrong with updating RET for user " + curUID,
          error
        );
      }
    }
  }

  function handleRemovalModalSubmit() {
    dispatchRoomState({ type: "LEAVE" });
    onPageSwitch({ state: "HOME", back: true });
  }

  function renderSwitch(rankingStatus) {
    if (RModalVisible) {
      console.log(
        "not rendering ranking. RModalVisible is visible",
        RModalVisible
      );
      return <></>;
    }
    switch (rankingStatus) {
      case "ROUND":
        return (
          <RoundRanking
            user={user}
            gameStats={gameStats}
            curGame={curGame}
            onRankingSwitch={(pairs) => {
              updateRET(pairs);
              console.log("waiting for 2s to toggle total ranking in");
              setTimeout(() => {
                setCurRanking("TOTAL");
                setWaitingStatus("Total Ranking");
                console.log("switched to total ranking");
              }, 2000);
            }}
            allPlayersLoaded={() =>
              setWaitingStatus("Updating Total Ranking...")
            }
            roomID={roomID}
            pageEntered={pageEntered}
            online={online}
            players={players}
            playerStatus={playerStatus}
          />
        );
      case "TOTAL":
        return (
          <TotalRanking
            user={user}
            roomID={roomID}
            gameStats={gameStats}
            curGame={curGame}
            gameIDs={gameIDs}
            onPageSwitch={onPageSwitch}
            online={online}
            players={players}
            playerStatus={playerStatus}
            rankingEntered={totalRankingEntered}
            RModalVisible={RModalVisible}
          />
        );
      default:
        return <></>;
    }
  }

  const variants = {
    enter: {
      x: "100%",
      y: "0",
    },
    center: {
      x: 0,
      y: 0,
    },
    exit: {
      x: "100%",
      y: "0",
    },
  };

  return (
    <div className="screen-wrap" id="post-game-wrapper">
      <div id="post-game" className="page">
        <div id="players-status" className="topBar width-100">
          <p id="waiting-status" className="bodyText">
            {waitingStatus}
          </p>
        </div>

        {curRanking === "ROUND" ? (
          <TwoStoryBanner text="NICE JOB!" />
        ) : (
          <TwoStoryBanner text="LEADERBOARD" />
        )}

        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={curRanking}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.7 }}
            onAnimationComplete={() =>
              setTotalRankingEntered(curRanking === "TOTAL")
            }
            className="rankingWrapper"
          >
            {renderSwitch(curRanking)}
          </motion.div>
        </AnimatePresence>

        <RemovalNotifyModal
          visible={RModalVisible}
          onSubmit={handleRemovalModalSubmit}
        />
      </div>
    </div>
  );
}

export default PostGame;
