import React from "react";
import "./CreateRoom.css";

function CreateRoom({ onSubmit }) {
  return (
    <div id="createRoom">
      <p className="instructionText">Or &nbsp;</p>
      <button id="create-new-party" onClick={() => onSubmit()}>
        CREATE A NEW PARTY
      </button>
    </div>
  );
}

export default CreateRoom;
