import React from "react";

const Profile = () => {
  return (
    <div className="screen-wrap" id="home-page">
      <div className="page"></div>
    </div>
  );
};

export default Profile;
