import React, { useState } from "react";
import emailListSignup from "../helpers/email-signup.js";

import "./EmailSignUp.css";

function EmailSignUp() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  function handleSubmit(evt) {
    emailListSignup(email)
      .then(() => {
        setStatus(`Thanks! Added ${email}.`);
        setEmail("");
      })
      .catch(() => {
        setStatus("Something went wrong. Sorry.");
      });
  }

  return (
    <div className="card" id="signup">
      <span id="signup-instruction" className="bodyText">
        Get updated when we launch new games:
      </span>
      <div id="subscribe-form">
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="email-subscribe"
          className="instructionText"
          placeholder="Email Address"
        />
        <button id="email-submit" onClick={handleSubmit}>
          SIGN UP
        </button>
      </div>
      <p id="subscribe-msg" className="bodyText">
        {status}
      </p>
    </div>
  );
}

export default EmailSignUp;
