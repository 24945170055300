import makeStore from "./makeStore";

const roomStateReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...action.payload,
      };
    case "JOIN_GAME_LOBBY":
      // when transition from party lobby to game lobby
      return {
        started: true,
        ...state,
      };
    case "NEXT_GAME":
      return {
        ...state,
        curGame: state.curGame + 1,
      };
    case "LEAVE":
      return {};
    default:
      return state;
  }
};

const [RoomStateProvider, useRoomStateStore, useRoomStateDispatch] = makeStore(
  roomStateReducer,
  {}
);

export { RoomStateProvider, useRoomStateStore, useRoomStateDispatch };
