import makeStore from "./makeStore";

const RoomInfoReducer = (state, action) => {
  // consist of properties 'host' and 'partyId'
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const [RoomInfoProvider, useRoomInfoStore, useRoomInfoDispatch] = makeStore(
  RoomInfoReducer,
  {}
);

export { RoomInfoProvider, useRoomInfoStore, useRoomInfoDispatch };
