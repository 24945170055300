import React from "react";
import ReactTooltip from "react-tooltip";

function BGLTooltip({ display }) {
  return (
    <ReactTooltip id={`tooltip-${display}`} place="top" effect="solid">
      {display}
    </ReactTooltip>
  );
}

export default BGLTooltip;
