/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useRef } from "react";

import {
  useToggle,
  useRoomDataEffect,
  usePageTitleOnMount,
  useRealtimeDatabaseEntry,
} from "../../hooks/customHooks";
import ExitModal from "../../components/ExitModal";
import GameTutorial from "../../components/GameTutorials";
import PlayerAvatar from "../../components/PlayerAvatar";
import TwoStoryBanner from "../../components/TwoStoryBanner";
import BackButton from "../../components/BackButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import { realtime_db } from "../../services/firebase";
import "./GameLobby.css";

import leaveRoom from "../../helpers/leave-room.js";
import { usePlayersDispatch, usePlayersStore } from "../../store/playersStore";
import { useGameInfoStore } from "../../store/gameInfoStore";
import { useRoomStateDispatch } from "../../store/roomStateStore";
import RemovalNotifyModal from "../../components/RemovalNotifyModal";

import { get, child, update, set, onValue, ref } from "firebase/database";

function GameLobby({ user, roomID, onPageSwitch, pageEntered, isMobile }) {
  const [RModalVisible, toggleRModal] = useState(false);
  const removed = useRef(false);
  if (RModalVisible) {
    removed.current = true;
  }

  const [loading, setLoading] = useState(false);

  const [EMVisible, toggleEMVisible] = useToggle();

  const [gameState, setGameState] = useState({
    curRound: NaN,
    totalRoundNum: NaN,
    gameName: "",
    gameID: "",
  });

  const [playerStatus, setPlayerStatus] = useState({});
  const { gameIDs } = useGameInfoStore();
  const curGame = useRealtimeDatabaseEntry(
    `/rooms/${roomID}/room_state/curGame`
  );

  const dispatchRoomState = useRoomStateDispatch();

  useEffect(() => {
    console.log(removed.current);
    if (Number.isInteger(curGame) && !removed.current) {
      setGameState({
        curRound: curGame + 1,
        totalRoundNum: gameIDs.length,
        gameName: idToName(gameIDs[curGame]),
        gameID: gameIDs[curGame],
      });
    }
  }, [curGame, gameIDs]);

  useRoomDataEffect(
    useCallback((player_status_data) => {
      if (removed.current) {
        console.log("not setting player status because this player is removed");
        return;
      }

      setPlayerStatus(player_status_data);

      console.log("checking all players' status: ", player_status_data);

      const playerIDs = Object.keys(player_status_data);
      let isEveryoneReady = true;
      for (let i = playerIDs.length - 1; i >= 0; i--) {
        isEveryoneReady =
          isEveryoneReady && player_status_data[playerIDs[i]].ready;
      }
      if (isEveryoneReady && !removed.current) {
        console.log("everyone is ready. display loading...");
        setLoading(true);
      } else {
        console.log("not all players ready.");
      }
    }, []),
    roomID,
    "playerStatus"
  );

  const hostUID = useRealtimeDatabaseEntry(`/rooms/${roomID}/host/uid`);

  const players = usePlayersStore();
  const dispatchPlayers = usePlayersDispatch();

  function idToName(id) {
    return id.replace("-", " ").toUpperCase();
  }

  function handleRemovalModalSubmit() {
    dispatchRoomState({ type: "LEAVE" });
    onPageSwitch({ state: "HOME", back: true });
  }

  usePageTitleOnMount("Game Lobby");

  useRoomDataEffect(
    useCallback(
      (data) => {
        // switch to game if everyone is ready
        if (!removed.current && data.stage === "game") {
          console.log("triggering page switch to game...");
          onPageSwitch({
            state: "GAME",
            gameID: gameIDs[data.curGame],
            room: roomID,
            back: false,
          });
        }
      },
      [onPageSwitch, roomID, gameIDs]
    ),
    roomID,
    "room_state"
  );

  // update player store if some players leave the room
  useRoomDataEffect(
    useCallback(
      (data) => {
        if (removed.current) {
          return;
        }
        // remove player if not in the party
        if (!data[user.uid]) {
          toggleRModal(true);
          removed.current = true;
          console.log("player removed", removed.current);
          return;
        }
        dispatchPlayers({ type: "INITIALIZE", payload: data });
      },
      [dispatchPlayers, user, removed, toggleRModal]
    ),
    roomID,
    "players"
  );

  // update player status in realtime db
  async function updatePlayerStatusOnReadyUp() {
    const roomRef = ref(realtime_db, `rooms/${roomID}`);
    try {
      const room = await get(roomRef);
      const room_data = room.val();
      const uid = user.uid;

      if (room && room.exists && !room_data.playerStatus[uid].ready) {
        console.log("marking player " + uid + " as ready.");
        const playerStatusRef = child(roomRef, `playerStatus/${uid}`);
        await update(playerStatusRef, {
          ready: true,
          proceeded: false,
          partyAgain: false,
        });
        console.log("marked player " + uid + " as ready.");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Oops! Something went wrong with getting ready: ", error);
    }
  }

  function handleExitModalSubmit() {
    toggleEMVisible();
    dispatchRoomState({ type: "LEAVE" });
    onPageSwitch({ state: "HOME", back: true });
    leaveRoom(user.uid, roomID);
  }

  return (
    <div className="screen-wrap" id="game-lobby-wrapper">
      <div className="page" id="game-lobby">
        <div className="topBar width-100">
          <BackButton onClick={toggleEMVisible} />
          <span id="roundNumInfo">
            {" "}
            ROUND {gameState.curRound} OF {gameState.totalRoundNum}{" "}
          </span>
        </div>

        <TwoStoryBanner text={gameState.gameName} />

        <GameTutorial gameID={gameState.gameID} />

        <div id="getting-ready" className="width-100">
          {loading ? (
            <div id="start-load">
              <LoadingSpinner />
            </div>
          ) : (
            <button id="start">
              <img
                id="check-icon"
                className="icon"
                src="img/icons/correct.png"
                alt="ready"
                onClick={() => {
                  updatePlayerStatusOnReadyUp();
                }}
              />
              <p className="instructionText" id="imReady">
                READY!
              </p>
            </button>
          )}

          <div id="players-game-lobby">
            {Object.keys(players)
              .sort()
              .map((player) => (
                <PlayerAvatar
                  user={user}
                  state="GAME_LOBBY"
                  key={player}
                  avatar={playerStatus[player] && players[player].avatar}
                  name={playerStatus[player] && players[player].name}
                  id={player}
                  hostUID={hostUID}
                  room={roomID}
                  isReady={playerStatus[player] && playerStatus[player].ready}
                  isReturned={true}
                  tooltip={true}
                />
              ))}
          </div>
        </div>

        <ExitModal
          visible={EMVisible}
          onSubmit={handleExitModalSubmit}
          onClose={toggleEMVisible}
        />

        <RemovalNotifyModal
          visible={RModalVisible}
          onSubmit={handleRemovalModalSubmit}
        />
      </div>
    </div>
  );
}

export default GameLobby;
