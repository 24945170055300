import makeStore from "./makeStore";

const gameStatsReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const [GameStatsProvider, useGameStatsStore, useGameStatsDispatch] = makeStore(
  gameStatsReducer,
  {}
);

export { GameStatsProvider, useGameStatsStore, useGameStatsDispatch };
